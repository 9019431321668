import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import shortcodes from "../components/shortcodes"

import { pageSlug } from "../lib/page-slug-full"
import { breakpoints } from "../parameters/theme"

const shortcodeComps = {
  ...shortcodes,
  a: shortcodes.MDXLink,
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  max-width: ${breakpoints.tablet}px;
  padding: 1em 1em 0;
  position: relative;
  overflow-wrap: break-word;

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  & > *:not(:first-child) {
    margin-top: 1em;
  }

  & > a {
    display: block;
  }

  .main-content {
    align-items: flex-start;
    display: flex;
  }

  .breadcrumb {
    position: absolute;
    top: -2em;

    &::before {
      content: "\\2190";
      margin-right: 5px;
    }
  }

  .avatar {
    margin-top: 0;
    width: 30%;

    .gatsby-image-wrapper {
      border-radius: 50%;
      padding-top: 100%;
      position: relative;
      width: 100%;
    }
    .gatsby-image-wrapper > div {
      position: absolute;
    }
  }

  .person-biography {
    padding-left: 2em;
    margin-top: 0;
    width: 70%;

    & > p:first-of-type {
      font-size: 1.5em;
    }

    & > :not(:first-child) {
      margin-top: 1em;
    }
  }

  h2 {
    font-size: 1.5em;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;

    .main-content {
      display: block;

      & > *:not(:first-child) {
        margin-top: 1em;
      }
    }

    .breadcrumb {
      position: static;
    }

    .avatar {
      width: 100%;

      .gatsby-image-wrapper {
        border-radius: 5%;
      }
    }

    .person-biography {
      padding-left: 0;
      width: 100%;
    }
  }
`

const TeamMember = ({
  data: { contentfulPerson: person, allContentfulArticle: articles },
  location,
}) => (
  <Layout
    location={location}
    pageSlug={pageSlug(person)}
    pageType="page,person"
    pageClassName="page"
    title={person.name}
    header={person.name}
    description={person.tagline ? person.tagline : ""}
    nodeData={person}
  >
    <MDXProvider components={shortcodeComps}>
      <Container>
        <Link to="/team" className="breadcrumb">
          Meet the team
        </Link>
        <section className="main-content">
          <figure className="avatar">
            <Link to={`/team/${person.slug}`}>
              <Img
                fluid={person.avatarImage.fluid}
                alt={person.name}
                title={person.name}
              />
            </Link>
          </figure>
          <article className="person-biography">
            {person.childContentfulMdx && person.childContentfulMdx.childMdx ? (
              <MDXRenderer>
                {person.childContentfulMdx.childMdx.body}
              </MDXRenderer>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: person.biography ? person.biography.biography : "",
                }}
              ></div>
            )}
          </article>
        </section>
        {person.roles && person.roles.indexOf("Coach") > -1 ? (
          <aside className="section-cta-button">
            <shortcodeComps.CTAButton link="/r/get-coached">{`Let ${person.name} coach you`}</shortcodeComps.CTAButton>
          </aside>
        ) : (
          ""
        )}
        {articles.edges.length ? (
          <section className="posts">
            <h2 id="posts">Articles by {person.name}</h2>
            <div className="articles-by-category">
              {articles.edges.map(({ node: article }) => (
                <React.Fragment key={article.slug}>
                  {article.titleImage ? (
                    <article className="post post-cover-title post-cover-small">
                      <Link to={`/${article.slug}`} title={article.title}>
                        <Img
                          fluid={article.titleImage.fluid}
                          alt={article.titleImage.title}
                        />
                        <h3>{article.title}</h3>
                      </Link>
                    </article>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
          </section>
        ) : null}
      </Container>
    </MDXProvider>
  </Layout>
)

export const query = graphql`
  query GetPersonByID($contentfulId: String) {
    contentfulPerson(contentful_id: { eq: $contentfulId }) {
      name
      slug
      tagline
      roles
      avatarImage {
        title
        fluid(quality: 100, maxWidth: 512) {
          ...GatsbyContentfulFluid
        }
      }
      childContentfulMdx {
        childMdx {
          body
        }
      }
      biography {
        biography
      }
      internal {
        type
      }
    }
    allContentfulArticle(
      filter: { author: { contentful_id: { eq: $contentfulId } } }
      sort: { fields: createdAt }
    ) {
      edges {
        node {
          title
          slug
          titleImage {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default TeamMember
